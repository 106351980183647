/* You can add global styles to this file, and also import other style files */

html {
  background: url(/assets/pictures/bg_pattern.png);
  font-family: sans-serif;
}

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: url(/assets/pictures/Geometric_pattern.png);
  background-position: right top,right bottom;
  background-repeat: repeat-y;
  font-family: 'Titillium Web', sans-serif;
  color: #4d4d4e;
}

html, body {
  height: 100%;
}

.completewrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.outletwrapper {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.container-navbar {
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.register-text {
  display: block;
  padding-top: 10px;
}

.footer-newsletter a {
  color: #2aa7e0!important;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  text-decoration: none;
  color: #333;
}

.dropdown {
  cursor: pointer;
}

.news-navbar {
  display: inline-flex;
}

.news-icon {
  padding-left: 8px;
  padding-top: 8px;
}

.form-group > label {
  font-size: 17px;
}

.btn {
  font-size: 17px!important;
}

.form-border-login {
  background-color: #ebebeb;
  border: 1px solid #b8b8b8;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .5);
}

.heading-form {
  font-size: 25px;
  color: #4d4d4e;
  padding-bottom: 22px;
}

.fail-text-selection {
  color: #ea0000;
}

.invalid-feedback {
  font-size: 84%;
  cursor: default!important;
}

.custom-file-input.is-invalid~.invalid-feedback {
  margin-top: 15px;
}

.form-control-file.is-invalid {
  border: 1px solid;
  border-color: red;
  border-radius: .25rem;
}

input[type="file"]{
  opacity: 0;
  cursor: pointer;
  font-size: 0;
}

.custom-file-label {
  overflow: hidden;
  cursor: pointer;
}

.custom-file-input{
  height: unset;
}

.custom-file{
  cursor: pointer!important;
}

.file-button-delete {
  display: flex;
}

.margin-top-7 {
  margin-top: 7px;
}
.margin-top-bottom-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-left-2 {
  margin-left: 2px;
}

.margin-right-2 {
  margin-right: 2px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.no-padding-left {
  padding-left: 0;
}
.inner-wrapper {
  width: 1140px;
  margin: 0 auto;
  position: relative;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.nav-left {
  background-color: #d4d4d4;
  /** float: left; */
  box-shadow: 2px 0px 5px grey;
  padding-right: 0;
}

.nav-left li.nav-item:hover a.nav-link {
  color:#fff;
}

.div-navigation-home {
  display: flex;
  padding-bottom: 20px;
}

.div-navigation-home-inactive {
  max-width: 100px;
}

.navigation-home-heading {
  font-weight: 600;
}

.nav-left-home {
  background-color: #ffffff;
  box-shadow: 0px 0px 11px grey;
  padding-right: 0;
  border-radius: 10px;
  margin-left: 10px;
  max-width: 270px;
  padding-bottom: 7px;
  float: left;
}

.hide-nav-left-home {
  display: none;
}

.nav-left-home li.nav-item:hover a.nav-link {
  color:#fff;
}

.nav-item-home {
  padding-left: 20px;
  /* padding-bottom: 0; */
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 1px solid #adadad;
}

.nav-item-home-sub {
  padding-left: 28px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 1px solid #adadad;
}

.nav-item-home-sub:hover {
  cursor: pointer;
  background-color: rgb(233 233 233);
}

.home-navigation-newsletter.collapsed > span.fa.fa-angle-down::before {
  content: "\f105";
}

.home-navigation-newsletter-sub.collapsed > span.fa.fa-angle-down::before {
  content: "\f105";
}

.icon-navigation-home {
  font-size: 23px;
  color: #000000;
}

.home-navigation-newsletter {
  font-size: 18px;
  font-weight: 600;
}

.home-navigation-newsletter-sub {
  font-size: 16px;
}

.home-navigation-category {
  font-size: 17px;
  padding: .5rem 1rem;
}

.home-navigation-category-item {
  cursor: pointer;
}

.home-navigation-category-item:not(:first-child) {
  border-top: 1px solid #d3d3d3;
}

.home-navigation-category-item:hover {
  background-color: #eeeeee;
}

.home-navigation-category-icon-active {
  color: #797979;
}

.navigation-show-button {
  font-size: 21px;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 2px;
  margin-top: 1px;
  background-color: #ffffff;
  box-shadow: 1px 0px 11px grey;
  border-radius: 6px;
  cursor: pointer;
  height: 32px;
}

.navigation-inactive-button > span.fa.fa-angle-double-left::before {
  content: "\f101";
}

.alert-home {
  text-align: center;
}

.entries-home {
  padding-bottom: 15px;
}

.newsletter-detail-above {
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 1px 0px 11px grey;
  border-radius: 6px;
  margin-bottom: 35px;
}

.single-entry-home {
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  box-shadow: 1px 0px 11px grey;
  border-radius: 6px;
  cursor: pointer;
}

.single-entry-home:hover {
  background-color: #f7f7f7;
}

.single-entry-home:not(:first-child) {
  margin-top: 35px;
}

.single-entry-home-picture-div {
  display:flex;
}

.single-entry-home-picture {
  max-width: 260px;
  min-width: 160px;
  max-height: 195px;
}

.single-entry-home-text {
  padding-left: 15px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  border-left: 1px solid #adadad;
  font-family: 'Oxygen';
}

.single-entry-home-newsletter {
  color: rgb(44 143 97);
  font-weight: 600;
  font-size: 20px;
}

.single-entry-home-date {
  color: rgb(161 161 161);
  font-size: 14px;
  margin-bottom: 10px;
}

.entry-home-text-heading {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
  font-family: 'Titillium Web';
}

.entry-home-text-readmore {
  margin-top: -9px;
  font-size: 13px;
}

.print-detail-entry {
  margin-bottom: 20px;
}

.btn-print {
  background-color: #2BA2DB;
  color: white !important;
}

.btn-print:hover {
  background-color: #2388b8;
}

.first-section-entry {
  min-height: 300px;
}

.heading-detail-entry {
  font-family: 'Titillium Web';
  font-size: 29px;
  line-height: 1.1;
  margin-bottom: 10px;
}

.picture-div-entry {
  display: grid;
}

.picture-detail-entry {
  max-width: 400px;
  max-height: 300px;
  width: 100%;
  margin-right: 25px;
  margin-bottom: 5px;
}

.picture-container {
  position: relative;
  max-width: 400px;
  max-height: 300px;
  width: inherit;
  height: inherit;
  margin-right: 25px;
}

.picture-button-left {
  height: 100%;
  position: absolute;
  left: 0px;
  background: rgba(91, 91, 91, 0.5);
  width: 25px;
  cursor: pointer;
}

.picture-button-right {
  height: 100%;
  position: absolute;
  right: 0px;
  background: rgba(91, 91, 91, 0.5);
  width: 25px;
  cursor: pointer;
}

.icon-picture-switch {
  color: white;
  top: 45%;
  position: relative;
  font-size: 25px;
}

.picture-subtext-entry {
  max-width: 400px;
  font-size: 14px;
  color: #898989;
  padding-top: 5px;
}

.teaser-detail-entry {
  font-weight: 600;
}

.text-detail-entry {
  padding-top: 20px;
  font-family: 'Oxygen', serif;
  font-weight: 300;
  display: inline-block;
}

.form-detail-entry {
  min-height: 330px;
}

.author-section-detail-entry {
  background-color: #e9e9e9;;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 15px;
  min-height: 190px;
}

.picture-author-detail-entry {
  border-radius: 16px;
  margin: 15px;
  float: left;
}

.text-author-detail-entry {
  padding-top: 10px;
  margin: 10px;
  font-family: 'Oxygen', serif;
  font-weight: 300;
}

.note-heading-subscribe {
  font-size: 14px;
  color: #28a3db;
}

.input-dataprotection {
  margin-right: 10px;
  margin-left: 5px;
  transform: scale(1.5);
}

.input-dataprotection.is-invalid~.invalid-feedback {
  display: block;
}

.text-dataprotection {
  display: inline-block;
}

.link-colored {
  color: #28a3db;
}

.outlet-admin-area {
  float: left;
}

.heading-admin-navbar {
  font-size: 21px;
  padding: .5rem 1rem;
}

.icon-heading-admin-navbar {
  background: url(/assets/pictures/favicon.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 16px;
  width: 16px;
  display: inline-block;
}

.nav-item-admin:hover {
  background-color: #f7f7f7;
}

.table-custom {
  background-color: #ffffff;
  padding-top: 25px;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
  display: inline-block;
}

.form-layout-custom {
  background-color: #ffffff;
  padding-top: 25px;
  padding-bottom: 30px;
  border-radius: 2%;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
}

.button-table-menu {
  margin-bottom: 20px;
  font-size: 1.0rem;
}
.button-table-create {
  background-color: #ccd866;
}

.button-table-refresh {
  color: #28a3db;
  border: 1px solid;
  background-color: #ffffff;
}

.rotate {
  animation: a 2s infinite linear;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.menu-right-table {
  float: right;
  display: flex;
}

.search-field-table {
  margin-right: 15px;
}

.sort-icon-table {
  margin-left: 10px;
  cursor: pointer;
  font-size: 18px;
}

.pagination-table {
  color: #28a3db;
  display: inline-flex;
  font-size: 14px;
}

.select-pagination {
  color: #28a3db;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  cursor: pointer;
  padding: 6.4px;
  margin-right: 20px;
  margin-left: 6px;
}

.page-item:not(.disabled) {
  cursor: pointer;
}

.icons-table {
  display: inline-block;
}

.icon-table-options {
  font-size: 21px;
  cursor: pointer;
}

.icon-red-table {
  font-size: 21px;
  color: #ea0000;
}

.icon-green-table {
  font-size: 21px;
  color: #00aa15;
}

.icon-yellow-table {
  font-size: 21px;
  color: #ff9900;
}

.icons-table > .icon-table-options:not(:first-child){
  margin-left: 15px;
}

td > .icon-table-options:not(:first-child){
  margin-left: 15px;
}

quill-editor.is-invalid~.invalid-feedback {
  display: block;
}

.navbar-fixed-top {
  /** background-color: transparent; */
  border-color: transparent;
  font-family: 'Titillium Web',sans-serif;
  opacity: .95;
  z-index: 100;
  top: 0;
  border-width: 0 0 1px;
  border-radius: 0;
  position: fixed;
  right: 0;
  left: 0;
  background-color: white;
  min-height: 50px;
}

.navbar-brand {
  padding: 33px 25px;
  color: rgba(0,0,0,.9);
}

.nav-item {
  list-style: none;
}

.navbar-light .navbar-nav .nav-link {
  color: #333;
}

.nav-link.active {
  color: #2ba2db !important;
}

.de_en {
  float: left;
  padding-right: 5px;
}

.en_de {
  float: right;
  padding-left: 5px;
}

.language-wrapper .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 17px;
  margin-top: 2px;
}

.language-wrapper .switch input {
  display: none;
}

input:checked+.slider {
  background-color: #28a3db;
}

.loginbutton {
  float: left;
  padding-right: 30px;
  cursor: pointer;
}

.language-wrapper {
  display: inline-block;
  padding-top: 7px;
}

.color-picker-admin {
  cursor: pointer;
}
.language-wrapper .active {
  color: #2ba2db;
}

.slider.round {
  border-radius: 34px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccd866;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round:before {
  border-radius: 50%;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0;
  bottom: -4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0 0 5px grey;
}

.icon-inform {
  margin-left: 15px;
  margin-right: 5px;
}

.entry-inform {
  margin-top: 10px;
}

.navbar-toggler {
  position: relative;
  float: left;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.bonares-icon {
  background-image: url(/assets/pictures/logo.full.png);
  height: 41px;
  width: 151px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 6px;
}

.outletwrapper {
  margin-top: 145px;
}

ul, div, a, li{
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
}

.footer {
  z-index: 0;
  min-height: 200px;
  background-image: url(/assets/pictures/footer-back.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-color: #fff;
  margin-top: 40px;
  padding-bottom: 20px;
}

.footer ul li {
  list-style: none;
  display: inline-block;
}

.footer ul li a {
  text-decoration: none;
  font-size: 16px;
  color: #333333;
  margin-right: 50px;
  float: left;
  cursor: pointer;
  margin-top: 60px;
  min-height: 28px;
  padding-top: 5px;
}

.menu-text-padding-tab {
  padding-top: 10px;
  float: left;
  display: block;
}

.twitter-wrapper {
  position: relative;
  height: 33px;
  width: 33px;
  padding-left: 0;
  padding-top: 0;
  background: url(/assets/pictures/twitter.png) no-repeat;
  background-position: 0 0;
}

/** Auflösungsabhängige Regeln **/

@media (max-width: 266px) {
  .outletwrapper {
    margin-top: 185px;
  }
}

@media (max-width: 320px) {
  .navigation-show-button {
    display: none;
  }

  .single-entry-home-picture {
    max-width: 200px;
  }
}

@media (max-width: 600px) {
  .single-entry-home {
    display: block;
  }

  .single-entry-home-picture {
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .single-entry-home-text {
    border-top: 1px solid #adadad;
    border-left: none;
    margin-left: 0;
  }
}

@media (min-width: 600px) {

  .single-entry-home-picture {
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media (max-width: 500px) {

  .page-buttons {
    padding-top: 10px;
  }

  .form-detail-entry {
    padding-left: 30px;
    padding-right: 30px;
  }

  .picture-detail-entry {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.picture-detail-entry {
  float: left;
}

@media (min-width: 601px) {
  .form-detail-entry {
    font-size: 17px;
  }

  .picture-div-entry {
    float: left;
  }
}



@media (max-width: 767px) {
  .footer ul li a {
    margin-top: 20px
  }

  .footer ul li {
      display: table
  }

  .inner-wrapper {
    width: 100%;
    margin: 0 auto
  }
  .nav-left {
    width: 100%;
    margin-bottom: 15px;
  }

  .nav-item-admin {
    margin-top: 6px;
  }

}

@media (min-width: 768px) and (max-width:1281px) {
  .footer ul li a {
    margin-right:40px
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .footer {
    background-size: 90% auto;
    padding-bottom: 35px;
  }

  .footer ul li a {
    margin-right: 15px !important
  }

  .inner-wrapper {
    width: 100%;
    margin: 0 auto
  }
}

@media (min-width: 320px) and (max-width:767px) {
  .footer {
      background-size:90% auto;
      padding-bottom: 30px
  }
}

@media (min-width: 992px) and (max-width:1279px) {
  .inner-wrapper {
    width: 100%;
    margin: 0 auto
  }
}

@media (width: 1280px) {
  .inner-wrapper {
    width: 100%;
    margin: 0 auto
  }
}

@media (min-width: 1200px){
  .form-row-custom {
    display: flex;
  }
}
@media (min-width: 992px){
  .container-navbar {
    display: flex;
  }

  .nav-item {
    padding-left: 10px;
  }

  .login-language {
    float: left;
    padding-left: 18px;
  }

  .navbar-nav {
    float: left;
  }

  .navbar-head {
    display: block !important;
  }

  .navbar-collapse {
    float: left;
    padding-right: 100px;
  }
}
@media (min-width: 768px){

  .custom-file{
    min-width: 206px;
  }

  .nav-left {
    float: left;
  }

  .nav-left > li:nth-child(2){
    border-top: 1px #adadad solid;
  }

  .nav-left > li{
    border-bottom: 1px #adadad solid;
  }

  .outlet-admin-area {
    margin-left: 30px;
  }

  .home-navigation-category {
    padding-bottom: 0;
  }

  .form-detail-entry {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.loading-div {
  display: flex;
}

.site-spin {
  margin-right: auto;
  margin-left: auto;
  font-size: 50px;
}

.spin {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
  display: block;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/** Editor Fonts for Detail View */

.ql-font-titilliumweb {
  font-family: 'Titillium Web', serif;
  font-weight: 400;
}

.ql-font-titilliumweblight {
  font-family: 'Titillium Web', serif;
  font-weight: 300;
}

.ql-font-titilliumwebsemibold {
  font-family: 'Titillium Web', serif;
  font-weight: 600;
}

.ql-font-oxygen {
  font-family: 'Oxygen', serif;
}

.ql-font-oxygenlight {
  font-family: 'Oxygen', serif;
  font-weight: 300;
}

.ql-font-oxygenbold {
  font-family: 'Oxygen', serif;
  font-weight: 700;
}

.editor-text .ql-container {
  font-family: 'Oxygen', serif;
  font-weight: 300;
}

/** Newsletter Regeln */

.buttons-preview-newsletter {
  padding-bottom: 15px;
}

.btn-preview {
  margin-top: 10px;
  margin-right: 15px;
  font-size: 15px!important;
}

.complete-newsletter {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid;
  border-color: #adadad;
  margin-bottom: 25px;

}

.body-newsletter {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}

.heading-newsletter {
  padding-top: 8px;
  display: grid;
}

.heading-newsletter-logo {
  padding-top: 8px;
  width: 100%;
}

.heading-newsletter-title {
  margin-left: auto;
  color: #6c6c6c;
}

.dotted-line {
  max-width: 100%;
  border:none;
  border-top: 2px dotted #bababa;
  margin-top: 15px;
}

.category-newsletter {
  padding-top: 9px;
}

.category-newsletter-heading {
  font-size: 17px;
}

.category-newsletter-entry {
  padding-bottom: 5px;
  margin-top: -9px;
}

.entry-heading {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: -6px;
  padding-top: 15px;
  line-height: 1.1;
}

.entry-text {
  word-break: break-word;
}

.entry-read-more {
  margin-top: -13px;
}

.footer-newsletter {
 text-align: center;
 font-size: 12px;
 color: #6c6c6c;
 padding-top: 15px;
}
