.ql-container {
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
  font-size: 15px;
  height: 100%;
  margin: 0px;
  position: relative;
}

.ql-editor .ql-font-titilliumweb {
  font-family: 'Titillium Web', serif;
  font-weight: 400;
}

.ql-editor .ql-font-titilliumweblight {
  font-family: 'Titillium Web', serif;
  font-weight: 300;
}

.ql-editor .ql-font-titilliumwebsemibold {
  font-family: 'Titillium Web', serif;
  font-weight: 600;
}

.ql-editor .ql-font-oxygen {
  font-family: 'Oxygen', serif;
}

.ql-editor .ql-font-oxygenlight {
  font-family: 'Oxygen', serif;
  font-weight: 300;
}

.ql-editor .ql-font-oxygenbold {
  font-family: 'Oxygen', serif;
  font-weight: 700;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'Titillium';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=titilliumweb]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=titilliumweb]::before {
  content: 'Titillium';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=titilliumweblight]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=titilliumweblight]::before {
  content: 'Titillium light';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=titilliumwebsemibold]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=titilliumwebsemibold]::before {
  content: 'Titillium semi-bold';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=oxygen]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=oxygen]::before {
  content: 'Oxygen';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=oxygenlight]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=oxygenlight]::before {
  content: 'Oxygen light';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=oxygenbold]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=oxygenbold]::before {
  content: 'Oxygen bold';
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=titilliumweb]::before {
  font-family: 'Titillium Web', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=titilliumweblight]::before {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=titilliumwebsemibold]::before {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=oxygen]::before {
  font-family: 'Oxygen', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=oxygenlight]::before {
  font-family: 'Oxygen', sans-serif;
  font-weight: 300;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=oxygenbold]::before {
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
  padding: 8px;
}

.ql-snow .ql-picker.ql-font {
  width: 140px;
}

.ql-tooltip {
  z-index: 1;
}
